export const listEnvironment = [
  { textEnvironment: "Habílitacion", valueEnvironment: "H" },
  { textEnvironment: "Demo", valueEnvironment: "D" },
  { textEnvironment: "Productivo", valueEnvironment: "P" }
];


export const listTechnologyProvider = [
  { textEnvironment: "HKA", valueEnvironment: "1" },
  { textEnvironment: "EDN", valueEnvironment: "2" }
];


export const listAPIbyProvider = [
  { provider: "HKA", environment: "H", urls: [{ type: "document", url:"https://emision21v4.thefactoryhka.com.co/ws/v1.0/Service.svc?wsdl"}] },
  { provider: "HKA", environment: "D", urls: [{ type: "document", url:"https://demoemision21v4.thefactoryhka.com.co/ws/v1.0/Service.svc?wsdl"}] },
  { provider: "HKA", environment: "P", urls: [{ type: "document", url:"https://emision21v4.thefactoryhka.com.co/ws/v1.0/Service.svc?wsdl"}] },
  { provider: "EDN", environment: "H", urls: [{ type: "access",   url:"https://login.microsoftonline.com/organizations/oauth2/v2.0/token"}, { type: "document", url:"https://api.cdcstage.origon.cloud/"}] },
  { provider: "EDN", environment: "D", urls: [{ type: "access",   url:"https://login.microsoftonline.com/organizations/oauth2/v2.0/token"}, { type: "document", url:"https://api.cdcstage.origon.cloud/"}] },
  { provider: "EDN", environment: "P", urls: [{ type: "access",   url:"https://login.microsoftonline.com/organizations/oauth2/v2.0/token"}, { type: "document", url:"https://api.cdc.origon.cloud/"}] },
];
